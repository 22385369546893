.board {
  aspect-ratio: 1 / 1;
}

.black {
  transform: rotate(180deg);
}

.black .piece {
  transform: rotate(180deg);
}

.light {
  background-color: #F4EAC7;
}

.dark {
  background-color: #968864;
}

.dark.selected {
  background-color: #bbcb2b;
}

.light.selected {
  background-color: #f7f769;
}

.movable div {
  height: 40%;
  width: 40%;
  background-color: black;
  opacity: 0.3;
}

.capturable.light {
  background-color: #AAA38B;
}

.capturable.dark {
  background-color: #695F46;
}

.capturable.dark div {
  height: 110%;
  width: 110%;
  background-color: #968864;
}

.capturable.light div {
  height: 110%;
  width: 110%;
  background-color: #F4EAC7;
}

.checked div {
  height: 70%;
  width: 70%;
  background-color: red;
  box-shadow: 0 0 10px 10px red;
  opacity: 0.65;
}


.scrollbar::-webkit-scrollbar {
  width: 20px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #777777;
}

/* Right triangle placed top left flush. */
.tri-right.border.left-top:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -40px;
	right: auto;
  top: -8px;
	bottom: auto;
	border: 32px solid;
	border-color: #8F8F8F transparent transparent transparent;
}
.tri-right.left-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -10px;
	right: auto;
  top: 0px;
	bottom: auto;
	border: 15px solid;
	border-color: #8F8F8F transparent transparent transparent;
}

/* Right triangle placed top right flush. */
.tri-right.border.right-top:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -40px;
  top: -8px;
	bottom: auto;
	border: 32px solid;
	border-color: #8F8F8F transparent transparent transparent;
}
.tri-right.right-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -10px;
  top: 0px;
	bottom: auto;
	border: 15px solid;
	border-color: #8F8F8F transparent transparent transparent;
}
